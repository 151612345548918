import { LOAD, UPDATE, CREATE } from 'model/fspConfigPayALoan';
import { load, update, create } from 'sdk/fspConfigPayALoan';
import { takeLatest } from 'redux-saga/effects';
import { callAction, ORM } from '../util';
import { loadFSPProfileAction } from './export';

export const createFspPalConfig = () =>
  callAction({
    api: create,
    dataName: ORM,
    formatSuccessMessage: () => `FSP Config has been successfully created`,
    type: CREATE,
  });

const updateFspPalConfig = () =>
  callAction({
    api: update,
    dataName: ORM,
    formatSuccessMessage: () => `Pay A Loan Config successfully updated`,
    type: UPDATE,
  });

function* updateAndPopulateExport(action) {
  const updateAction = updateFspPalConfig();

  if (yield updateAction(action)) {
    yield loadFSPProfileAction(action);
  }
}

export default function* saga() {
  yield takeLatest(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeLatest(CREATE.ACTION, createFspPalConfig);
  yield takeLatest(UPDATE.ACTION, updateAndPopulateExport);
}
